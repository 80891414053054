import { cloneDeep } from "lodash";
import { NodesResponse } from "~/data/home/interface";

export const buildTree = (nodes: NodesResponse[]) => {
    const tree = cloneDeep(nodes);
    const map = new Map();
    tree.forEach((node) => map.set(node.node_id, node));
    tree.forEach((node) => {
        const parent = map.get(node.parent_id);
        if (parent) {
            if (!parent.children) {
                parent.children = [];
            }
            parent.children.push(node);
        }
    });
    return tree.filter((node) => node.level === 0);
};